import React from 'react';

const AboutRestaurant = ({ children, details, menu, spanishName, title }) => (
  <section className="about-room">
    <div className="container">
      <h3 className="font-gtaM mt-5 text-center">{spanishName}</h3>
      <h1>{title}</h1>
      <div className="justify-content-between row">
        <div className="col-lg-5">
          <h3 className="font-gtaM">About This Restaurant</h3>
          <p>
            {children}
          </p>
          <a href="https://www.instagram.com/theloggiaatpalacio" target="_blank">
            <button className="btn">LEARN MORE</button>
          </a>
        </div>
        <div className="col-lg-6">
          <h3 className="font-gtaM">Operational Hours and Contact Details</h3>
          <p className="mb40">
            {details.details}
          </p>
        </div>
      </div>
    </div>
    <div className="container mt60">
      <div className="row">
        <div className="col-12 text-center">
          <div className="image-container text-center">
            <img alt="restaurant menu" className="mt-3" src={menu} />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutRestaurant;
