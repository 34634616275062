import React, { Component } from 'react';
import get from 'lodash/get';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import AboutRestaurant from '../../components/about-restaurant';
import BookingForm from '../../components/booking-form';
import Footer from '../../components/footer';
import HeroSlider from '../../components/hero-slider';
import Layout from '../../components/layout';
import NavSection from '../../components/nav-section';
// import RedroomEventsSection from '../../components/redroom-events';

const slideImages = [
  {
    url: '/images/loggia-1.JPG',
  },
  {
    url: '/images/loggia-2.JPG',
  },
  {
    url: '/images/loggia-3.JPG',
  },
  {
    url: '/images/loggia-4.JPG',
  },
];

export class LoggiaRestaurantArea extends Component {
  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
    const {
      aboutThisRestaurant,
      details,
      menu,
      title,
    } = get(this, 'props.data.allContentfulRoomLoggiaRestaurant.edges')[0].node;
    
    const footer = get(this, 'props.data.allContentfulFooter.edges')

    const bookingForm = get(this, 'props.data.allContentfulBookingForm.edges')[0].node;
    return (
      <Layout>
        <div className="sm-transNav">
          <NavSection navMenus={navMenus} navTheme="nav-transparent" />
        </div>
        <Helmet title={title} />
        <HeroSlider slides={slideImages} />
        <AboutRestaurant
          details={details}
          menu={menu.file.url}
          // spanishName="El Loggia"
          title={title}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: aboutThisRestaurant.aboutThisRestaurant
            }}
          ></p>
        </AboutRestaurant>
        {/* <RedroomEventsSection room={title} /> */}
        <BookingForm bookingForm={bookingForm}/>
        <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default LoggiaRestaurantArea;

export const pageQuery = graphql`
  query LoggiaRestaurantQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulRoomLoggiaRestaurant {
      edges {
        node {
          aboutThisRestaurant {
            aboutThisRestaurant
          }
          details {
            details
          }
          menu {
            file {
              url
            }
          }
          title
        }
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          pageTitle
          slug
          submenu
        }
      }
    }
    allContentfulBookingForm {
      edges {
        node {
          header
          firstNameInputPlaceholder
          lastNameInputPlaceholder
          contactNoInputPlaceholder
          emailInputPlaceholder
          bookingTypeInputPlaceholder {
            name
            value
          }
          dateInputPlaceholder
          timeInputPlaceholder
          venueInputPlaceholder {
            name
            value
            selected_value
          }
          messageInputPlaceholder
          newsletterInputPlaceholder
          submitInputPlaceholder
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
